<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { postMsgBind, postMsgReadAction, postMsgList } from "@/api/set";
import { BASE } from "@/api/index";
export default {
  name: "",

  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {
    "$store.state.token": {
      handler(newval, oldval) {
        console.log(
          `The token of in sessionStorage changed from '${oldval}' to '${newval}'`
        );
        if (newval) {
          this.openWebSocket();
        }
      }
    }
  },
  async created() {
    this.$store.commit("updateToken", sessionStorage.getItem("token"));
    this.$store.commit("updateUnread", sessionStorage.getItem("unread_num"));
    // let socket = new WebSocket("wss://sit-scf.bjjiunong.com:2346");
    let self_url = BASE.PRO.split("//")[1];
    console.log(self_url);
    let socket = new WebSocket(`wss://${self_url}/wss`);
    this.$store.commit("connectionSocket", socket);
    if (this.$store.state.token) {
      this.openWebSocket();
    }
  },
  mounted() {},
  beforeDestroy() {
    // if (this.socket) {
    //   this.socket.close(); // 在组件销毁前关闭 WebSocket 连接。
    // }
  },
  methods: {
    // 链接websocket
    openWebSocket() {
      this.$store.state.socket.onopen = () => {
        console.log("WebSocket 已连接");
        var login_data = {
          type: "login",
          client_name: JSON.parse(sessionStorage.getItem("userInfo")).username,
          room_id: 1,
          admin_id: JSON.parse(sessionStorage.getItem("userInfo")).admin_id
        };
        console.log(
          "websocket握手成功，发送登录数据:" + JSON.stringify(login_data)
        );
        this.$store.state.socket.send(JSON.stringify(login_data));
        this.getMessage();
      };
      this.$store.state.socket.onclose = function() {};
      this.$store.state.socket.onerror = error => {
        console.error("WebSocket 错误:", error);
      };
    },
    // 获取推送消息
    getMessage() {
      let self_client_id = "";
      // 使用你的 WebSocket URL
      console.log(this.$store.state.socket);
      this.$store.state.socket.onmessage = async e => {
        console.log("onmessage", e.data);
        let data = JSON.parse(e.data),
          res = {};
        self_client_id = data.client_id;
        switch (data.type) {
          case "login":
            try {
              let { client_id } = await postMsgBind({
                client_id: self_client_id,
                admin_id: JSON.parse(sessionStorage.getItem("userInfo"))
                  .admin_id,
                type: 1
              });
              self_client_id = client_id;
            } catch (error) {
              console.error("postMsgBind", error);
            }
            break;
          case "push":
            res = data;
            sessionStorage.setItem("unread_num", data.unread_num);
            this.$store.commit("updateUnread", data.unread_num);
            this.$notify({
              title: "消息提醒",
              dangerouslyUseHTMLString: true,
              position: "bottom-right",
              message: `<div class=notice-box><div class=notice-mb>发起时间：${res.created_at ||
                "—"}</div>
      <div class=notice-mb>客户名称：${res.buyer_name || "—"}</div>
      <div class='notice-flex notice-mb'><div class=flex>发起人：${res.creator_name ||
        "—"}</div><div class=flex>发起角色：${res.creator_role_name ||
                "—"}</div></div>
      <div class='notice-flex notice-mb'><div class=flex>分类：${res.service_cate_label ||
        "—"}</div><div class=flex>类型：${res.type_label || "—"}</div></div>
      <div>PI号：${res.cabinet_code || "—"}</div>
      </div>
      `,
              duration: 0,
              onClose: async () => {
                try {
                  let { count } = await postMsgReadAction({ id: data.id });
                  sessionStorage.setItem("unread_num", count);
                  this.$store.commit("updateUnread", count);
                } catch (error) {
                  sessionStorage.setItem("unread_num", 0);
                  this.$store.commit("updateUnread", 0);
                  console.error("postMsgReadAction", error);
                }
              }
            });
            break;
        }
      };
    },
    buttons(e) {
      console.log(e, "1111111111111");
    }
  }
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
.el-notification__title {
  text-align: center;
  margin-bottom: 20px !important;
}
.notice-box {
  .notice-flex {
    display: flex;
    .flex {
      flex: 1;
    }
  }
  .notice-mb {
    margin-bottom: 5px;
  }
}
.flexbuttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .button1 {
    margin-right: 20px;
  }
  .button2 {
  }
}
.el-notification__group {
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f8fe;
  width: 100%;
  height: 100vh;
  // overflow: hidden;
}
</style>
