import { BASE } from './index';
import axios from './http';

const nmput = {
    //内贸入库工单保存
    putsave: params => axios.post(`${BASE.PRO}/api/wo/dt/put/save`,  params ),
    //内贸入库单详情
    putget: params => axios.post(`${BASE.PRO}/api/wo/dt/put/get`,  params ),
    //内贸入库单作废
    putvoid: params => axios.post(`${BASE.PRO}/api/wo/dt/put/void`,  params ),
    //内贸入库单发送
    putsend: params => axios.post(`${BASE.PRO}/api/wo/dt/put/send`,  params ),
    //内贸入库单处理异常动作
    putcancel: params => axios.post(`${BASE.PRO}/api/wo/dt/put/cancel`,  params ),
    //内贸入库管理列表
    putlist: params => axios.post(`${BASE.PRO}/api/dt/wms/put/list`,  params ),
    //内贸入库管理详情
    putinfo: params => axios.post(`${BASE.PRO}/api/dt/wms/put/info`,  params ),
    //内贸入库入库
    putreceipt: params => axios.post(`${BASE.PRO}/api/dt/wms/put/receipt`,  params ),
    //内贸入库确定
    putconfirm: params => axios.post(`${BASE.PRO}/api/dt/wms/put/confirm`,  params ),
    //内贸制单列表
    purchaselist: params => axios.post(`${BASE.PRO}/api/bs/dt/order/purchase/list`,  params ),
    //制单商品列表
    goodslist: params => axios.post(`${BASE.PRO}/api/bs/dt/order/goods/list`,  params ),
    //出库商品列表
    outgoodslist: params => axios.post(`${BASE.PRO}/api/wo/dt/out/goods/list`,  params ),
    //保存制单
    purchasesave: params => axios.post(`${BASE.PRO}/api/bs/dt/order/purchase/save`,  params ),
    //制单详情
    purchaseinfo: params => axios.post(`${BASE.PRO}/api/bs/dt/order/purchase/info`,  params ),
    //作废制单
    purchasecancel: params => axios.post(`${BASE.PRO}/api/bs/dt/order/purchase/cancel`,  params ),
    //确认制单
    purchaseconfirm: params => axios.post(`${BASE.PRO}/api/bs/dt/order/purchase/confirm`,  params ),
    //首款审批详情
    purchaseinfoapproval: params => axios.post(`${BASE.PRO}/api/bs/dt/order/approval/purchase/info`,  params ),
    //首款发起审批
    purchasesendapproval: params => axios.post(`${BASE.PRO}/api/bs/dt/order/approval/purchase/send`,  params ),
    //首款审批列表
    purchaselistapproval: params => axios.post(`${BASE.PRO}/api/bs/dt/order/approval/list`,  params ),
    //首款撤销审批
    purchaserevokeapproval: params => axios.post(`${BASE.PRO}/api/bs/dt/order/approval/revoke`,  params ),
    //首款重新发起审批
    purchasereissueapproval: params => axios.post(`${BASE.PRO}/api/bs/dt/order/approval/reissue`,  params ),
    //延期详情
    purchaseDeferInfo: params => axios.post(`${BASE.PRO}/api/bs/dt/order/approval/defer/info`,  params ),
    //保存延期
    purchaseDeferSave: params => axios.post(`${BASE.PRO}/api/bs/dt/order/approval/defer/save`,  params ),
    //发送审批
    purchaseDeferSend: params => axios.post(`${BASE.PRO}/api/bs/dt/order/approval/defer/send`,  params ),
    //货物延期-品类详情
    purchaseDeferDetail: params => axios.post(`${BASE.PRO}/api/bs/dt/order/approval/defer/goodsDetail`,  params ),
    //出库工单保存修改
    outsave: params => axios.post(`${BASE.PRO}/api/wo/dt/out/save`,  params ),
    //出库单详情
    outinfo: params => axios.post(`${BASE.PRO}/api/wo/dt/out/info`,  params ),
    //出库单发送工单
    outsend: params => axios.post(`${BASE.PRO}/api/wo/dt/out/send`,  params ),
    //出库单详情作废
    outvoid: params => axios.post(`${BASE.PRO}/api/wo/dt/out/void`,  params ),
    //出库工作台列表
    outlist: params => axios.post(`${BASE.PRO}/api/dt/wms/out/list`,  params ),
    //出库工作台详情
    wmsoutinfo: params => axios.post(`${BASE.PRO}/api/dt/wms/out/info`,  params ),
    //获取提货商品列表结算
    salegoodslist: params => axios.post(`${BASE.PRO}/api/wo/dt/sale/goods/list`,  params ),
    //内贸确定入库
    outreceipt: params => axios.post(`${BASE.PRO}/api/dt/wms/out/receipt`,  params ),
    //内贸销售制单作废
    salelistvoid: params => axios.post(`${BASE.PRO}/api/wo/dt/sale/void`,  params ),
    //内贸结算添加获取详情
    getsettleinfo: params => axios.post(`${BASE.PRO}/api/wo/dt/sale/get/settle/info`,  params ),
    //内贸结算添加获取详情添加、编辑
    dtsalesave: params => axios.post(`${BASE.PRO}/api/wo/dt/sale/save`,  params ),
    //发送财务工作台
    salesend: params => axios.post(`${BASE.PRO}/api/wo/dt/sale/send`,  params ),
    //财务驳回
    salereject: params => axios.post(`${BASE.PRO}/api/wo/dt/sale/reject`,  params ),
    //财务通过
    saleconfirm: params => axios.post(`${BASE.PRO}/api/wo/dt/sale/confirm`,  params ),
    //获取结算单详情
    saleinfo: params => axios.get(`${BASE.PRO}/api/wo/dt/sale/info`,  {params} ),
    //内贸销售制单列表
    salelist: params => axios.get(`${BASE.PRO}/api/wo/dt/sale/list`,  {params} ),
    


   

};

export default nmput;
