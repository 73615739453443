
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tagsList: [],//tab页面缓存
    classificationList: [],//出入库工作台分类
    futuresList: [],//期货工作台分类
    promptList: [],//现货工作台分类
    cachedViews: [],//需要缓存的页面
    menuchildren: [],//菜单左侧布局数据
    menuchildrenname: '',//菜单左侧布局数据
    jump_path: '',//当前是那个页面
    const: '',//页面标识

    contentlist: [],//页面装修成什么样子数据源
    renovationitem: {},//装修页面缓存
    quality: {},//装修页面属性参数\
    token: '',//token
    unread_num: '',//消息未读数量
    socket:null //WebSocket链接值
  },
  mutations: {
    // 出入库工作台页面分类缓存
    classification(state, value) {
      console.log(value);
      state.classificationList = value
    },
    // 期货工作台页面分类缓存
    futures(state, value) {
      console.log(value);
      state.futuresList = value
    },
    // 现货工作台页面分类缓存
    prompt(state, value) {
      console.log(value);
      state.promptList = value
    },
    // 修改消息未读数量
    updateUnread(state, value) {
      console.log(value);
      state.unread_num = value
    },
    // 修改token
    updateToken(state, value) {
      console.log(value);
      state.token = value
    },
    // 链接WebSocket
    connectionSocket(state, value) {
      state.socket = value
    },
    // 断开WebSocket
    closeSocket(state, value) {
      state.socket = null
    },
    //路由切换数据菜单回显此方法
    replaceItem(state, view) {
      
      if (state.tagsList.some(v => v.path === view.path)) {
        let arr = []
        console.log(state.tagsList,'进来了吗')
        state.tagsList.forEach(el => {
          if (el.path == view.path) {
            el = view
          }
          arr.push(el)
        })

        state.tagsList = arr;
        console.log(state.tagsList,'更新了吗')
        return;
      } else {
        state.tagsList.push(
          Object.assign({}, view, {
            title: view.meta.title || 'no-name'
          })
        )
        state.cachedViews.push(
          view.name
        )
        state.menuchildren = []
        state.menuchildrenname = ''
        //待优化地方
        JSON.parse(sessionStorage.getItem("userInfo")).menu.forEach(el => {
          if (el.jump_path == view.path) {
            if (el.style == 1) {
              state.menuchildren = el.children
              state.menuchildrenname = el.name
            } else {
              state.menuchildren = []
              state.menuchildrenname = ''
            }
  
          } else {
            if (el.children && el.children.length > 0) {
              el.children.forEach(v => {
                if (v.jump_path == view.path) {
                  if (el.style == 1) {
                    state.menuchildren = v.children
                    state.menuchildrenname = v.name
                  } else {
                    state.menuchildren = []
                    state.menuchildrenname = ''
                  }
                } else {
                  if (v.children && v.children.length > 0) {
                    v.children.forEach(vv => {
                      if (vv.jump_path == view.path) {
                        if (el.style == 1) {
                          state.menuchildren = v.children
                          state.menuchildrenname = v.name
                        } else {
                          state.menuchildren = []
                          state.menuchildrenname = ''
                        }
                      } else {
  
  
                        if (vv.children && vv.children.length > 0) {
                          vv.children.forEach(vvv => {
                            if (vvv.jump_path == view.path) {
                              if (v.style == 1) {
                                state.menuchildren = vv.children
                                state.menuchildrenname = vv.name
                              } else {
                                state.menuchildren = []
                                state.menuchildrenname = ''
                              }
                            }
                          })
                        }
                      }
                    })
                  }
                }
              })
            }
          }
        });
        JSON.parse(sessionStorage.getItem("userInfo")).pageSet.forEach(el => {
          if (el.jump_path == view.path) {
            state.const = el.const
          }
        })
      }

     
      // console.log(view, '11111111111', JSON.parse(sessionStorage.getItem("userInfo")).menu,state.menuchildren, state.const )
    },
    addmenuchildren(state, data) {
      state.menuchildren = data
    },
    //添加当前是那个页面
    addjump_path(state, data) {

      state.jump_path = data
    },
    //添加当前是那个页面
    addconst(state, data) {
      state.const = data
    },
    //删除某tab条数据
    delTagsItemback(state, data) {
      console.log(state, data, '关闭时候穿的页面值')
      state.cachedViews = []
      let arr = []
      state.tagsList.forEach(el => {
        if (el.path != data) {
          arr.push(el)
          state.cachedViews.push(el.name)
        }
      })

      state.tagsList = arr
      // state.tagsList.splice(data.index, 1);
      // sessionStorage.setItem("tagsList", JSON.stringify(state.tagsList))
    },
    //跳转切换标识tab切换调用此方法
    addpathconst(state, data) {
      state.menuchildren = []
      state.menuchildrenname = []
      //待优化地方
      JSON.parse(sessionStorage.getItem("userInfo")).menu.forEach(el => {
        if (el.jump_path == data) {
          if (el.style == 1) {
            state.menuchildren = el.children
            state.menuchildrenname = el.name
          } else {
            state.menuchildren = []
            state.menuchildrenname = ''
          }

        } else {
          if (el.children && el.children.length > 0) {
            el.children.forEach(v => {
              if (v.jump_path == data) {
                if (el.style == 1) {
                  state.menuchildren = v.children
                  state.menuchildrenname = v.name
                } else {
                  state.menuchildren = []
                  state.menuchildrenname = ''
                }
              } else {
                if (v.children && v.children.length > 0) {
                  v.children.forEach(vv => {
                    if (vv.jump_path == data) {
                      if (el.style == 1) {
                        state.menuchildren = v.children
                        state.menuchildrenname = v.name
                      } else {
                        state.menuchildren = []
                        state.menuchildrenname = ''
                      }
                    } else {


                      if (vv.children && vv.children.length > 0) {
                        vv.children.forEach(vvv => {
                          if (vvv.jump_path == data) {
                            if (v.style == 1) {
                              state.menuchildren = vv.children
                              state.menuchildrenname = vv.name
                            } else {
                              state.menuchildren = []
                              state.menuchildrenname = ''
                            }
                          }
                        })
                      }
                    }
                  })
                }
              }
            })
          }
        }
      });
      JSON.parse(sessionStorage.getItem("userInfo")).pageSet.forEach(el => {
        if (el.jump_path == data) {
          state.const = el.const
        }
      })
    },
    //删除某tab条数据
    delTagsItem(state, data) {
      state.tagsList.splice(data.index, 1);
    },
    addtabJump(state, view) {
      if (state.cachedViews.some(v => v === view.name)) return
      state.cachedViews.push(
        view.name
      )
    },
    deletemenuJump(state, view) {
      //去除路径的/是为了保持给name一致
      let result = view.replace(/\//g, "");
      state.cachedViews = state.cachedViews.filter((item) => {
        return item !== result
      })
    },
    clearTags(state) {
      state.tagsList = []
      state.cachedViews = []
    },
    //装修页面布局缓存问题
    addcontentlist(state, item) {
      state.contentlist = JSON.parse(JSON.stringify(item))
    },
    //装修页面缓存的数据
    addrenovationitem(state, item) {
      state.renovationitem = JSON.parse(JSON.stringify(item))
    },
    //当前选中属性数据
    addquality(state, item) {
      state.quality = JSON.parse(JSON.stringify(item))
    }
  },
  actions: {
  },
  modules: {
  }
})
