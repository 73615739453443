import { BASE } from './index';
import axios from './http';

const Approval = {
    //审批列表
    approvallist: params => axios.post(`${BASE.PRO}/api/approval/list`,  params ),
    //审批详情
    approvalinfo: params => axios.post(`${BASE.PRO}/api/approval/info`,  params ),
    //发起审批
    approvalapproval: params => axios.post(`${BASE.PRO}/api/approval/approval`,  params ),
    //撤销审批
    approvalrevoke: params => axios.post(`${BASE.PRO}/api/approval/revoke`,  params ),
};

export default Approval;
